/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon79IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon79Icon(props: Icon79IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 32"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g clipPath={"url(#28lSXRd-5IWCa)"} fill={"currentColor"}>
        <path
          opacity={".4"}
          d={
            "M16 32a16 16 0 1116-16 16.019 16.019 0 01-16 16zm0-28a12 12 0 1012 12A12.013 12.013 0 0016 4z"
          }
        ></path>

        <path
          d={"M32 16h-4A12.013 12.013 0 0016 4V0a16.02 16.02 0 0116 16z"}
        ></path>
      </g>

      <defs>
        <clipPath id={"28lSXRd-5IWCa"}>
          <path fill={"currentColor"} d={"M0 0h32v32H0z"}></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon79Icon;
/* prettier-ignore-end */
